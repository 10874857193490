import style from "./Homebanner.module.scss";
import { useState, useEffect } from "react";
import AdComponent from "../../AdComponent/AdComponent";
import Button from "../../Button/Button";
import { useTranslation } from "next-i18next";

const HomeBanner = ({ handleClickDiscover }) => {
  const [adFormat, setAdFormat] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    window.innerWidth < 992 ? setAdFormat("fluid") : setAdFormat("horizontal");
  }, []);

  return (
    <div className={style.HomeBanner}>
      <div className={style.HomeBanner__wrapper}>
        <h1 className={style.HomeBanner__title}>{t("home.title")}</h1>
        <div className={style.HomeBanner__btn} onClick={handleClickDiscover}>
          <Button text={t("home.btn")} padding="14px 20px" fontSize="14px" />
        </div>
        {adFormat ? (
          <AdComponent className="ad__leaderboard" />
        ) : (
          <div className="HomeBanner__add"></div>
        )}
      </div>
    </div>
  );
};

export default HomeBanner;
