import style from "./HomeCard.module.scss";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import truncate from "../../../helpers/truncate";
import Link from "next/link";
import { useRouter } from "next/router";

const HomeCard = ({ game }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <Link href={`/${locale}/game/${game.id}`} passHref locale={locale}>
      <a className={style["game-card"]}>
        <Image
          loading='lazy'
          src={game.image}
          width={120}
          height={120}
          className={style["game-card__img"]}
          alt={t(`home.${game.game}.name`)}
        />
        <div className={style["game-card__description"]}>
          <h2 className={style["game-card__title"]}>{t(`home.${game.game}.name`)}</h2>
          <p className={style["game-card__text"]}>
            {truncate(t(`home.${game.game}.description`), 240)}
          </p>
        </div>
      </a>
    </Link>
  );
};

export default HomeCard;
