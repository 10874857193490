import style from "./BestGameCard.module.scss";
import { useRouter } from "next/router";
import Link from "next/link";

const BestGameCard = ({ img, title, to, description }) => {
  const { locale } = useRouter();

  return (
    <Link href={`/${locale}/game${to}`} passHref locale={locale}>
      <a className={style.BestGameCard}>
        <div
          className={style.BestGameCard__img}
          style={{ backgroundImage: "url(" + img.src + ")" }}></div>
        <div className={style.BestGameCard__textContainer}>
          <div
            className={
              style.BestGameCard__circle1 + " " + style.BestGameCard__circle
            }></div>
          <div
            className={
              style.BestGameCard__circle2 + " " + style.BestGameCard__circle
            }></div>
          <div
            className={
              style.BestGameCard__circle3 + " " + style.BestGameCard__circle
            }></div>
          <div
            className={
              style.BestGameCard__circle4 + " " + style.BestGameCard__circle
            }></div>
          <svg>
            <filter
              id="filter"
              x="-20%"
              y="-20%"
              width="140%"
              height="140%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="linearRGB">
              <feGaussianBlur
                stdDeviation="1 5"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                in="SourceGraphic"
                edgeMode="none"
                result="blur"
              />
            </filter>
          </svg>
          <div className={style.BestGameCard__text}>
            <h4 className={style.BestGameCard__title}>{title}</h4>
            <p className={style.BestGameCard__description}>{description}</p>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default BestGameCard;
