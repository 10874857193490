import Head from "next/head";
import { useEffect, useContext } from "react";
import styles from "./Home.module.scss";
import HomeBanner from "../components/home/HomeBanner/HomeBanner";
import BestGame from "../components/home/BestGame/BestGame";
import AdComponent from "../components/AdComponent/AdComponent";
import Content from "../components/home/Content/Content";
import ContactForm from "../components/ContactForm/ContactForm";
import PageLayout from "../layout/PageLayout";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { countByType } from "../dataProvider/code";
import { GameContext } from "../context/GameContext";
import Container from "../layout/container/Container";

export default function Home({ typeCount }) {
  const { scrollToContent } = useContext(GameContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (window.location.hash !== "#contact") return;

    var el = document.getElementById("contact-form");
    const timeout = setTimeout(() => {
      window.scroll({ top: el.offsetTop, behavior: "smooth" });
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      <Head>
        <title>{t("metadata.home.title")}</title>
        <meta name="description" content={t("metadata.home.description")} />
      </Head>
      <PageLayout>
        <div className={styles["home"]}>
          <HomeBanner handleClickDiscover={scrollToContent} />
          <Container>
            <BestGame />
            <div className={styles["home__main"]}>
              <AdComponent className={styles["add-home--left"] + " " + "ad__skyscraper"} />
              <Content gameByCount={typeCount} />
              <AdComponent className={styles["add-home--right"] + " " + "ad__skyscraper"} />
            </div>
            <ContactForm />
          </Container>
          <div className={styles["home__separatorBottom"]}></div>
          <div className={styles["home__bg-bottom"]}></div>
        </div>
      </PageLayout>
    </div>
  );
}

export const getStaticProps = async ({ locale }) => {
  const typeCount = await countByType();

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"]), typeCount),
    },
    revalidate: 60 * 60 * 24,
  };
};
