import { useEffect } from "react";

const AdComponent = (props) => {
  useEffect(() => {
    const adsbygoogle = window.adsbygoogle || [];
    adsbygoogle.push({});
  }, []);

  return (
    <ins
      className={`adsbygoogle ${props?.className}`}
      style={{ display: "Block" }}
      data-ad-client="ca-pub-5098117046903146"
      data-ad-slot="4911619245"
      data-adtest="on"
    />
  );
};

export default AdComponent;
