import style from "./Pagination.module.scss";
import { CgPushChevronLeft, CgPushChevronRight } from "react-icons/cg";

const Pagination = ({
  cardsPerPage,
  totalCards,
  handlePageChange,
  currentPage,
  changeToNextPage,
  changeToPreviousPage,
}) => {
  const pageNumber = [];

  for (let i = 1; i <= Math.ceil(totalCards / cardsPerPage); i++) {
    pageNumber.push(i);
  }

  return (
    <nav>
      <ul className={style.Pagination}>
        {currentPage !== 1 && totalCards !== 0 && (
          <li className={style.Pagination__arrow} onClick={() => changeToPreviousPage()}>
            <CgPushChevronLeft color="white" size="20" />
          </li>
        )}
        {pageNumber.map((num) => (
          <li
            className={
              num === currentPage
                ? style.Pagination__item + " " + style.Pagination__itemActive
                : style.Pagination__item
            }
            onClick={() => handlePageChange(num)}
            key={num}>
            {num}
          </li>
        ))}
        {pageNumber.length !== currentPage && totalCards !== 0 && (
          <li className={style.Pagination__arrow} onClick={() => changeToNextPage()}>
            <CgPushChevronRight color="white" size="20" />
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
