import style from "./BestGame.module.scss";
import marioImg from "/public/games/best-game-img-mario-kart-tour.webp";
import pokemonGoImg from "/public/games/best-game-img-pokemon-go.webp";
import animalCrossingImg from "/public/games/best-game-img-animal-crossing.webp";
import { useTranslation } from "next-i18next";
import BestGameCard from "../BestGameCard/BestGameCard";

const BestGame = () => {
  const { t } = useTranslation();

  const bestGames = [
    {
      img: marioImg,
      title: t("home.bastGame.marioKartTour.name"),
      to: "/mario-kart-tour",
      description: t("home.bastGame.marioKartTour.description"),
    },
    {
      img: pokemonGoImg,
      title: t("home.bastGame.pokemonGo.name"),
      to: "/pokemon-go",
      description: t("home.bastGame.pokemonGo.description"),
    },
    {
      img: animalCrossingImg,
      title: t("home.bastGame.animalCrossingNewHorizons.name"),
      to: "/animal-crossing-new-horizons",
      description: t("home.bastGame.animalCrossingNewHorizons.description"),
    },
  ];

  return (
    <section className={style.BestGame}>
      <h2 className={style.BestGame__title}>{t("home.bestGameTitle")}</h2>
      <div className={style.BestGame__cards}>
        {bestGames.map((game, i) => (
          <BestGameCard {...game} key={i} />
        ))}
      </div>
    </section>
  );
};

export default BestGame;
